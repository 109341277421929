export class apiUrls {
  // Login
  login: string = '/admin/login';

  // All
  getAdminSettings: string = '/appSettings/getProject/';
  submitAdminSettings: string = '/appSettings';
  updateExpireDay: string = '/appSettings/updateExpireDay';

  // Project
  // createNewProject: string = '/appSettings/createProject';
  createNewProject: string = '/client/createProject';
  updateProject: string = '/appSettings';
  // getProjectList: string = '/appSettings/getProjectList';
  getProjectList: string = '/client/getProjectList';
  deleteProject:string=`/client/project`;
  deleteProjects:string=`/admin/deleteProject`;
  updateProjects: string = '/client/updateProjects';
  getProject: string = '/client/getProject';
  sendDeatils:string='/client/sendDeatils';
  getAllClients:string='/client/getAllClients';
  openLoginApi:string='/admin/openLoginApi';
  exportClient:string='/client/getProjectExport';

  // Image Upload
  uploadImage: string = '/appSettings/profile';
  uploadImage2: string = '/appSettings/upload';
  uploadImages: string = '/client/upload';

  // Build
  addBuild: string = '/addBuild';
  getBuild: string = '/addBuild';

  // view type
  getView = '/admin/getViewType';

  // Add Module
  addModule: string = '/admin/addModule';
  editModule: string = '/admin/editModule';
  getModule: string = '/admin/getModule';
  getModuleById: string = '/admin/getModuleById';
  deleteModule: string = '/admin/deleteModule';

  //--------------------------------------New Url----------------------------------------
  //plan releated
  addPlan: string = '/admin/addPlan';
  getAllPlans: string = '/admin/getAllPlans';
  getPlanbyId: string = '/admin/getPlanbyId';
  editPlan: string = '/admin/editPlan';
  createSubsciption: string = '/client/createSubsciption';
  getExtensionById: string = '/admin/getExtensionById';
  deleteExtension: string = '/admin/deleteExtension';
  trialDay: string = `/admin/extendExpireDay`;
  // createSubsciption:string='/v1/client/createSubsciption';

  // Feature
  getFeature: string = '/admin/getFeature';
  getFeatureById: string = '/admin/getFeatureById';
  postFeature: string = '/admin/addFeature';
  editFeature: string = '/admin/editFeature?id=';
  deleteFeature: string = '/admin/deleteFeature?id=';
  updateAllFeatures: string = '/admin/upateAllFeatures';
  updateFeature: string = '/admin/updateFeature';
  dashboard: string = '/admin/dashboard';
  upload: string = '/client/upload';

  // logs
  getLogs: string = '/admin/getLogs';
  getLogsById: string = '/admin/getLogsById';

  //creditAmount
  creditAmount: string = '/admin/addCreditAmount';

  //module type and subModule
  parentModule: string = '/admin/addParentModule';
  getParentModule: string = '/admin/getParentModule';
  getSubParentModule: string = '/admin/getSubModuleByParentId';

  //add Sheet
  add: string = '/admin/addSheet';
  get: string = '/admin/getSheet';

  //sub-admin
  subAdmin: string = '/admin/subAdmin'
  subAdminById:string = '/admin/subAdminById'

  // Filter API
  clientListFilter:string='/admin/clientListFilter'
}
